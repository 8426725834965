<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>

        <b-form
          v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="title"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`title.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model="form.title[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="sub_title"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`sub_title.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.sub_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.sub_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model="form.sub_title[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.sub_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                vid="order-input"
                :name="$t('labels.order')"
                rules="required|integer"
              >
                <b-form-group
                  :label="$t('labels.order')"
                  label-for="order-input"
                >
                  <b-form-input
                    id="order-input"
                    v-model="form.order"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="6"
              md='6'

            >
              <label>{{ $t('labels.type') }}</label>
              <v-select
                v-model="mainType"
                :dir="$store.state.appConfig.layout.direction"
                :options="types"
                :reduce="item => item.id"
                @input='updateSelectedData("main_type")'
                label="name"
                class="w-100"
              />
            </b-col>
            <b-col
              cols="6"
              md='6'
              v-if='mainType === "matches"'

            >
              <label>{{ $t('labels.sub_type') }}</label>
              <v-select
                v-model="form.sub_type"
                :dir="$store.state.appConfig.layout.direction"
                :options="subTypes"
                :reduce="item => item.id"
                @input='updateSelectedData("sub_type")'

                label="name"
                class="w-100"
              />
            </b-col>
            <b-col v-if='form.sub_type  === "championship" '
                   cols="6"
                   md='6'

            >
              <label>{{ $t('championship') }}</label>
              <v-select
                v-model="form.sub_type_id"
                :dir="$store.state.appConfig.layout.direction"
                :options="championships"
                :reduce="item => item.id"
                label="name_label"
                class="w-100"
              />
            </b-col>
            <b-col v-if='form.sub_type  === "league" '
                   cols="6"
                   md='6'

            >
              <label>{{ $t('league') }}</label>
              <v-select
                v-model="form.sub_type_id"
                :dir="$store.state.appConfig.layout.direction"
                :options="leagues"
                :reduce="item => item.id"
                label='title_label'
                class="w-100"
              />
            </b-col>
            <b-col v-if='form.sub_type_id && mainType === "matches"'
              cols="6"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="match"
                :name="$t('labels.match')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.matches')"
                >
                  <v-select
                    v-model="form.modelable_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="label"
                    :options="matches"
                    :reduce="item => item.value"
                    :placeholder="$t('labels.match')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col v-if='show_date'
              cols="6"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.date')"
                vid="start_date"

              >
                <b-form-group>
                  <DatePicker
                    v-model="form.date"
                    :label="$t('labels.date')"
                    @input='updateSelectedData()'

                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if='mainType === "news" '
              cols="6"
              md='6'

            >
              <label>{{ $t('news') }}</label>
              <v-select
                v-model="form.modelable_id"
                :dir="$store.state.appConfig.layout.direction"
                :options="news"
                :reduce="item => item.id"
                label="title_label"
                class="w-100"
              />
            </b-col>

            <b-col v-if='mainType === "analytics" '
                   cols="6"
                   md='6'

            >
              <label>{{ $t('analytic') }}</label>
              <v-select
                v-model="form.modelable_id"
                :dir="$store.state.appConfig.layout.direction"
                :options="analytics"
                :reduce="item => item.id"
                label="title_label"
                class="w-100"
              />
            </b-col>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="description"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`description.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model="form.description[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rows="4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                vid="image"
                :name="$t('labels.image')"
                rules="required"
              >
                <ImagePreview
                  v-model="image"
                  :label="$t('labels.image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>


            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {

    return {
      isDataLoaded:false,
      news: [],
      analytics:[],
      championships:[],
      leagues:[],
      matches:[],

      show_date :false,
      mainType:null,
      config: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
      },
      image: null,



      form: {
        title: {
          en: null,
          ar: null,
        },
        sub_title: {
          en: null,
          ar: null,
        },
        type: null,
        sub_type:null,
        date: null,
        order: null,
        modelable_id:null,
        modelable_type:null,

        description: {
          en: null,
          ar: null,
        },

        image: null,

      },
    }
  },

  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },


    mainType(val) {
      this.show_date = val === 'news' || val === 'analytics';
      this.form.type = val
    },


    'form.date': function(val) {
      if(this.form.type === 'news'){
        this.getNews(val)
      }
      if(this.form.type === 'analytics'){
        this.getAnalytics(val)
      }
    },
    'form.sub_type': function(val) {
      if(val === 'championship'){
        this.getChampionships(val)
      }
      if(val === 'league'){
        this.getLeagues(val)
      }
    },
    'form.sub_type_id': function(val) {
      if(this.form.sub_type === 'championship'){
        this.axios.get(`/matchesByType?championship_id=${val}`)
          .then(res => {
            this.matches = res.data
          })
        this.form.modelable_type = 'matches'

      }
      if(this.form.sub_type === 'league'){
        this.axios.get(`/matchesByType?league_id=${val}`)
          .then(res => {
            this.matches = res.data
          })
        this.form.modelable_type = 'matches'

      }

    },
  },

  computed: {
    types() {

      return[
        {
          id: 'matches',
          name: this.$t('labels.matches'),
        },
        {
          id: 'news',
          name: this.$t('news'),
        },
        {
          id: 'analytics',
          name: this.$t('analytics'),
        },
      ]
    },
    subTypes() {

      return[
        {
          id: 'championship',
          name: this.$t('championship'),
        },
        {
          id: 'league',
          name: this.$t('leagues'),
        },

      ]
    },

  },
  created() {

    if (this.isEdit) {
      this.loadData()
    }else {
      this.isDataLoaded = true
    }
  },


  methods: {
    getNews(val) {
      this.axios.get(`/newsByDate?created_at=${val}`)
        .then(res => {
          this.news = res.data
        })

      this.form.modelable_type = 'news'

    },
      getAnalytics(val) {
      this.axios.get(`/analyticsByDate?created_at=${val}`)
        .then(res => {
          this.analytics = res.data
        })
        this.form.modelable_type = 'analytics'

      },
    getChampionships() {
      this.axios.get('/lists/Championship')
        .then(res => {
          this.championships = res.data
        })
    },
    getLeagues() {
      this.axios.get('/lists/League')
        .then(res => {
          this.leagues = res.data
        })
    },

    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data

          this.form = {
            title: data.title,
            description: data.description,
            date: data.date,
            sub_title: data.sub_title,
            type: data.type,
            sub_type: data.sub_type,
            sub_type_id: data.sub_type_id,
            modelable_id: data.modelable_id,
            modelable_type: data.modelable_type,
            order: data.order,



          }
          this.image = data.image
          this.mainType = data.type




        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });

    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if($form.start_time){
        $form.start_time = $form.start_time + ' ' + this.start_time
      }
      if($form.end_time){
        $form.end_time = $form.end_time + ' ' + this.end_time
      }
      if (!($form.image instanceof File)) {
        delete $form.image
      }
      if (!($form.icon instanceof File)) {
        delete $form.icon
      }
      return $form
    },
    updateSelectedData(type = null) {

      if (type === 'main_type' && !this.isEdit) {
        this.form.sub_type = null
        this.form.date = null
        // this.form.sub_type_id = null
        this.form.modelable_id = null

      }
      if (type === 'sub_type' && !this.isEdit) {
        this.form.date = null
        // this.form.sub_type_id = null
        this.form.modelable_id = null

      }
    },
  },

}
</script>
